import React, { useContext, useEffect, useState } from 'react'
import { faBox, faCalendar, faPaperPlane } from '@fortawesome/pro-regular-svg-icons'
import IconWithBackground from '../IconWithBackground/IconWithBackground'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage } from '@fortawesome/pro-light-svg-icons'
import { getMessageThread, sendMessage } from '../../services/api/messages'
import DefaultLoader from '../Loaders/DefaultLoader'
import Avatar from '../Avatar/Avatar'
import IconButton from '../Button/IconButton'
import { OrderMessageThread, OrderMessageThreadMessage } from '../../types'
import MessageBlock from './MessageBlock'
import { formatDate, formatTime, isToday } from '../../helpers/utils'
import { TibiContext } from '../../context/ContextProvider'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { NavLink } from 'react-router-dom'

export interface MessageThreadProps {
    threadId: number | null
    onSend?: () => void
}

const MessageThread: React.FunctionComponent<MessageThreadProps> = ({ threadId, onSend }: MessageThreadProps) => {
    const { value }: any = useContext(TibiContext)
    const username: string = value.user.username

    const [threadData, setThreadData] = useState<OrderMessageThread | null>(null)
    const [threadMessages, setThreadMessages] = useState<OrderMessageThreadMessage[]>([])
    const [newMessage, setNewMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const isPackage = false
    let prevDate: string | null | undefined = null

    const sender = threadData?.concerns

    useEffect(() => {
        let ctrl: AbortController = new AbortController()
        if (threadId) {
            getMessageThreadData()
        }
        return () => ctrl.abort()
        // eslint-disable-next-line
    }, [threadId])

    useEffect(() => {
        const element = document.getElementById('lastMessage')
        element?.scrollIntoView()
    }, [threadMessages])

    const getMessageThreadData = async () => {
        setLoading(true)
        try {
            const params = { pageSize: 1000 }
            const data = await getMessageThread(threadId, params)
            setThreadData(data.thread)
            setThreadMessages(data.items)
        } catch (e) {
            console.error(e)
        }
        setLoading(false)
    }

    const send = async () => {
        if (newMessage) {
            const id = await sendMessage(threadId, { message: newMessage })
            setThreadMessages([
                ...threadMessages,
                {
                    id: id,
                    message: newMessage,
                    sender: username,
                    date: new Date(),
                    self: true
                }
            ])
            setNewMessage('')
            onSend?.()
        }
    }

    return (
        <div className='flex justify-center w-full h-full border rounded-tr-md rounded-br-md border-tibi-fadedPrimary bg-white'>
            {loading ? (
                <DefaultLoader />
            ) : threadId ? (
                <div className='flex flex-col relative w-full'>
                    <div className=''>
                        <header className='flex items-center p-3 border-b border-tibi-fadedPrimary max-h-[63px] gap-x-4 bg-white rounded-tl-md md:rounded-tl-none rounded-tr-md'>
                            {/* Back button */}
                            <NavLink to={`/dashboard/messages`} className='flex lg:hidden'>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </NavLink>
                            <Avatar name={sender} className='' />
                            <h4 className='font-medium text-tibi-linkColor'>{sender}</h4>
                        </header>
                        <div className='bg-tibi-blushPrimary py-3 px-3 flex space-x-3 border-b border-tibi-primary border-opacity-10 h-[73px]'>
                            <IconWithBackground icon={isPackage ? faBox : faCalendar} />
                            <div className='flex flex-col justify-center'>
                                <span className='font-medium'>{threadData?.order?.title}</span>
                                {threadData?.subOrder && (
                                    <span className='text-sm'>Deelopdracht: {threadData?.subOrder?.title}</span>
                                )}
                                <span className='text-xs'>
                                    {threadData?.order?.address}, {threadData?.order?.zipcode} {threadData?.order?.city}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='overflow-y-auto h-full bg-tibi-lightPrimary'>
                        {threadMessages.map((message: OrderMessageThreadMessage, i: number) => {
                            const formattedDate = formatDate(message.date, {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit'
                            })
                            const showDate = prevDate !== formattedDate
                            prevDate = formattedDate

                            const formattedTime = formatTime(message.date)

                            return (
                                <div
                                    key={message.id}
                                    className='flex flex-col'
                                    {...(i === threadMessages.length - 1 && { id: 'lastMessage' })}
                                >
                                    {showDate && (
                                        <div className='flex justify-center my-4 text-xs font-bold uppercase opacity-50'>
                                            {isToday(message.date) ? 'Vandaag' : formattedDate}
                                        </div>
                                    )}

                                    <div className='w-full'>
                                        <MessageBlock
                                            text={message.message}
                                            self={message.self}
                                            time={formattedTime}
                                            key={message.id}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className='flex w-full p-4 border border-tibi-fadedPrimary border-b-0 border-l-0 border-r-0 rounded-br-md gap-2 bg-white absolute bottom-0'>
                        <input
                            type='text'
                            className='border grow text-tibi-text text-base'
                            placeholder='Typ hier je bericht'
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' && send()}
                        />
                        <div className='flex justify-center'>
                            <IconButton icon={faPaperPlane} onClick={send} iconClassName='pr-1' />
                        </div>
                    </div>
                </div>
            ) : (
                <div className='self-center flex flex-col my-auto items-center'>
                    <FontAwesomeIcon className='text-tibi-primary text-2xl mb-1' icon={faMessage} />
                    <p className='text-gray-500'>Hier verschijnen de berichten die je verstuurt en ontvangt.</p>
                </div>
            )}
        </div>
    )
}

export default MessageThread
