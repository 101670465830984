import React, { MouseEventHandler } from 'react'

export interface ButtonProps {
    type?: 'button' | 'submit' | 'reset' | undefined
    disabled?: boolean
    href?: string
    onClick?: MouseEventHandler
    children?: React.ReactNode
    className?: string
}

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
    const { type, href, onClick, children, className, disabled } = props
    const defaultColorClasses = 'bg-tibi-primary text-white'
    const defaultClasses =
        'flex items-center justify-center rounded-md py-2 text-sm disabled:opacity-50 disabled:cursor-not-allowed uppercase px-3 space-x-2 hover:opacity-75 transition-opacity font-bold'

    const classes = defaultClasses + ' ' + (className ? className : defaultColorClasses)

    const markup = href ? (
        <a className={classes} href={href}>
            {children}
        </a>
    ) : (
        <button className={classes} disabled={disabled} onClick={onClick} type={type || 'button'}>
            {children}
        </button>
    )

    return markup
}

export default Button
