import { faSpinner } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, useContext, useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Button from '../components/Button/Button'
import background from '../images/icon-pattern.png'
import tibiLogo from '../images/TibiLogo.svg'
import { logIn } from '../services/auth/service'
import styles from './Login.module.scss'
import { getCurrentUser } from '../services/api/me'
import { TibiContext } from '../context/ContextProvider'
import { getOrderDeliveries } from '../services/api/deliveries'

interface LoginProps {}

const Login: FC<LoginProps> = () => {
    const navigate = useNavigate()
    const [error, setError] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const {
        register,
        formState: { isValid },
        handleSubmit
    } = useForm()
    const { setValue } = useContext(TibiContext)

    useEffect(() => {
        //Check if user is already logged in
    }, [])

    const onSignIn = async (data: FieldValues) => {
        setLoading(true)
        setError('')
        try {
            // Login first
            const result = await logIn({ username: data.email, password: data.password })

            // Get userdata after login
            const { data: userData } = await getCurrentUser()
            const deliveries = await getOrderDeliveries()

            // dispatch userData to context
            if (userData && deliveries) {
                setValue({ user: userData, deliveries: deliveries.items })
            }

            if (result) {
                navigate('/dashboard')
            }
        } catch (e: any) {
            setError(e.message)
        }
        setLoading(false)
    }

    return (
        <div
            className={styles.container}
            style={{
                backgroundImage: 'url(' + background + ')'
            }}
        >
            <header className='fixed top-0 left-0 z-20 w-full bg-transparent'>
                <div className='container px-4 mx-auto w-full flex justify-between p-6'>
                    <div>
                        <img src={tibiLogo} className='w-[80px] md:w-[114px]' alt='Tibimedia' />
                    </div>
                    <div>
                        <button className='bg-tibi-primary text-white rounded-md text-sm px-4 py-3 font-semibold'>
                            DEMO AANVRAGEN
                        </button>
                    </div>
                </div>
            </header>

            <div className='rounded-xl bg-white border p-10 text-left text-tibi-text max-w-[600px] w-full shadow-lg relative z-20'>
                <h3 className='text-3xl font-semibold text-center mb-8 text-tibi-mainHeading'>Welkom terug</h3>
                <form onSubmit={handleSubmit(onSignIn)} className='flex flex-col space-y-4'>
                    <span className='font-semibold'>Login</span>
                    <input
                        {...register('email', { required: true })}
                        disabled={loading}
                        className='border border-gray-200 rounded-md p-3'
                        placeholder='E-mail'
                        type='text'
                    />
                    <input
                        {...register('password', { required: true })}
                        className='border border-gray-200 rounded-md p-3'
                        disabled={loading}
                        placeholder='Password'
                        type='password'
                    />
                    <a href='/' className='text-tibi-text text-sm text-right'>
                        Problemen met inloggen?
                    </a>
                    <Button type='submit' disabled={!isValid || loading}>
                        {loading ? (
                            <>
                                <FontAwesomeIcon icon={faSpinner} className='mr-2' spin />
                                Logging in..
                            </>
                        ) : (
                            <span>Log in</span>
                        )}
                    </Button>
                    {error && <p className='text-red-700 mt-3 text-sm'>{error}</p>}
                </form>
            </div>
        </div>
    )
}

export default Login
