import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { getCategories } from '../../services/api/categories'
import { Address, ProductCategoryDetail } from '../../types'
import AddressForm from '../AddressForm/AddressForm'
import DynamicFaIcon from '../DynamicFaIcon/DynamicFaIcon'

interface OrderDetailSelectionProps {
    initialFormData?: Address
    handleFormChange(data: any, isValid: boolean): void
    onCategorySelect(cat: ProductCategoryDetail): void
    selectedOrderCategory: ProductCategoryDetail | undefined
}

const OrderDetailSelection: React.FunctionComponent<OrderDetailSelectionProps> = ({
    initialFormData,
    handleFormChange,
    onCategorySelect,
    selectedOrderCategory
}: OrderDetailSelectionProps) => {
    const [categories, setCategories] = useState([])

    //Mount
    useEffect(() => {
        let ctrl: AbortController = new AbortController()
        const fetchCategories = async () => {
            try {
                await getCategories().then((data: any) => {
                    setCategories(data)
                })
            } catch (e) {
                console.error(e)
            }
        }
        fetchCategories()
        return () => ctrl.abort()
    }, [])

    return (
        <div className='w-full flex flex-col'>
            <div className='w-full flex space-x-3 lg:space-x-10 justify-between h-[215px] border-tibi-blushPrimary border py-4'>
                {categories.map((cat: any) => {
                    const isActive = Boolean(selectedOrderCategory?.id === cat.id)
                    return (
                        <div
                            key={`step-${cat.id}`}
                            onClick={() => onCategorySelect(cat)}
                            className={`
                relative flex hover:border-tibi-primary transition-colors cursor-pointer flex-1 bg-white items-center justify-center space-y-[5px] flex-col border-2 rounded-md p-3
                ${isActive ? 'border-tibi-primary' : 'border-tibi-fadedPrimary'}
              `}
                        >
                            <DynamicFaIcon className='text-tibi-primary text-3xl' iconName={cat.icon} />
                            <h2 className='font-semibold text-xl'>{cat.name}</h2>
                            <p className='text-sm' dangerouslySetInnerHTML={{ __html: cat.description }}></p>
                            {isActive && (
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className='absolute text-4xl bg-white text-tibi-primary bottom-0 translate-y-1/2'
                                />
                            )}
                        </div>
                    )
                })}
            </div>
            {/* TODO Should be rendered conditionally based on selectedOrderCategory?.requestAddress */}
            {selectedOrderCategory && (
                <div className='w-full h-full flex flex-col mt-3 space-y-8 bg-white border rounded-md border-tibi-fadedPrimary p-6'>
                    <AddressForm onChange={handleFormChange} defaultValues={initialFormData} />
                </div>
            )}
        </div>
    )
}

export default OrderDetailSelection
