interface MessageBlockProps {
    text: string | undefined
    self: boolean | undefined
    time: string | number | undefined
}

const MessageBlock = ({text, self, time}: MessageBlockProps) => {
    const extraClasses = self ? 'float-end bg-tibi-lightGreen right-top' : 'bg-white left-top'

    return (
        <div className={'message-block relative rounded-md px-4 py-2 w-2/3 mb-4 mx-6 text-tibi-text ' + extraClasses}>
            <div className='text-sm w-11/12'>
                {text}
            </div>

            <div className='text-xs absolute right-2 bottom-2 opacity-70'>
                {time}
            </div>
        </div>
    )
}

export default MessageBlock
