import axios, { AxiosRequestConfig } from 'axios'
import { fetchAuthSession } from 'aws-amplify/auth'
// export const api_baseURL = `https://virtserver.swaggerhub.com/SIMON_11/TibimediaCustomerAPI/1.0.0`

// @ts-ignore
// axios.defaults.proxy.host = 'https://api.klanten-test.tibimedia.nl'
const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
export const api_baseURL = isDevelopment ? `/api` : process.env.REACT_APP_API_URL

const doRequest = async (config: AxiosRequestConfig, authorizationEnabled?: boolean) => {
    const defaultConfig: AxiosRequestConfig = {
        //Authentication configuration goes here
        // cors
    }

    const currentSession = await fetchAuthSession()

    const headers = {
        // Authorization: `Bearer ${currentSession.getIdToken().getJwtToken()}`
        // Authorization: `Bearer ${currentSession.getAccessToken().getJwtToken()}`
        Authorization: `Bearer ${currentSession.tokens?.idToken}`
    }

    return axios({
        ...{
            ...defaultConfig,
            ...config,
            headers: headers
        }
    })
}

export const get = (route: string, config?: AxiosRequestConfig) => {
    return doRequest({
        method: 'GET',
        url: api_baseURL + route,
        ...config
    })
}

export const put = (route: string, config: AxiosRequestConfig) => {
    return doRequest({
        method: 'PUT',
        url: api_baseURL + route,
        ...config
    })
}

export const post = (route: string, config: AxiosRequestConfig) => {
    return doRequest({
        method: 'POST',
        url: api_baseURL + route,
        ...config
    })
}

export const remove = (route: string, config: AxiosRequestConfig) => {
    return doRequest({
        method: 'REMOVE',
        url: api_baseURL + route
    })
}
