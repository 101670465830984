import { get, post } from './api'

export const getMessageThreads = async (params?: { pageSize?: number }) => {
    const { data } = await get('/messageThreads', {
        params: {
            ...params
        }
    })
    return data
}

export const getMessageThread = async (threadId: number | null, params: { page?: number, pageSize?: number } = {}) => {
    const { data } = await get(`/messageThreads/${threadId}?page=${params.page}&pageSize=${params.pageSize}`)
    return data
}

export const sendMessage = async (threadId: number | null, params: { message: string }) => {
    const { data } = await post(`/messageThreads/${threadId}`, { data: params })
    return data
}

export const createMessageThread = async (params: { order?: number, subOrder?: number }) => {
    const { data } = await post(`/messageThreads`, { data: params })
    return data
}
