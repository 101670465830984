import { faHouse, faBox, faCalendar, faChevronDown, faUndo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import IconWithBackground from '../IconWithBackground/IconWithBackground'
import OrderPhaseLabel from '../OrderPhaseLabel/OrderPhaseLabel'
import AppointmentIndicator from '../AppointmentIndicator/AppointmentIndicator'
import { Order, OrderLine, Product } from '../../types'
import { formatDate } from '../../helpers/utils'
import { createMessageThread } from '../../services/api/messages'
import StartThreadButton from '../StartThreadButton/StartThreadButton'
import { useNavigate } from 'react-router-dom'
import ProductItem from '../ProductItem/ProductItem'

type OrderAction = 'startThread' | 'cancel' | 'viewDetails'

interface OrderItemProps {
    data: Order
    showPhase?: boolean
    actionButtons?: OrderAction[]
}
const OrderItem: React.FunctionComponent<OrderItemProps> = ({
    data,
    showPhase = false,
    actionButtons
}: OrderItemProps) => {
    const [open, setOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    //For reference
    const currentOrder: Order = data

    const isPackage: boolean | undefined = currentOrder.products && currentOrder.products.length > 1
    const hasProductOptions: boolean | undefined = currentOrder.products?.some((p) => p.options && p.options.length > 0)

    const createMessage = async (orderId: number) => {
        setLoading(true)
        const params: any = {
            order: currentOrder.id
        }
        if (orderId !== currentOrder.id) {
            params['subOrder'] = orderId
        }
        try {
            const messageId = await createMessageThread(params)
            navigate(`/dashboard/messages/?selectedThread=${messageId}`)
        } catch (e) {
            console.log(e)
        }

        setLoading(false)
    }

    const handleStartThread = ({ value }: { value: number }) => {
        createMessage(value)
    }

    let threadOptions: { label: string | JSX.Element; value: number | undefined }[] = [
        {
            label: 'Contact administratie',
            value: currentOrder.id
        }
    ]

    const subItems =
        currentOrder.products?.map((product: OrderLine) => {
            return {
                label: (
                    <>
                        Contact over <strong>{product.name}</strong>
                    </>
                ),
                value: product.id
            }
        }) || []

    threadOptions = [...threadOptions, ...subItems]

    return (
        <div className='flex relative flex-col w-full pl-4 pr-4 text-tibi-text py-2 border-b border-b-tibi-fadedPrimary last:border-b-0'>
            <div className='flex items-center w-full flex-wrap gap-y-2 justify-between'>
                {/*
                    //Product type,
                    //Singular = Displays first products information as icon
                    //Multiple = Displays "Pakket" with specific icon
                */}

                <div className='flex space-x-3 items-center w-full md:w-6/12 lg:w-4/12 pr-6'>
                    <div className='flex items-center justify-center text-sm w-5'>
                        {(isPackage || hasProductOptions) && (
                            <button type='button' onClick={() => setOpen(!open)}>
                                <FontAwesomeIcon icon={faChevronDown} rotation={open ? 180 : undefined} />
                            </button>
                        )}
                    </div>
                    {/* @Todo: display dynamic icon here */}
                    <IconWithBackground icon={isPackage ? faBox : faCalendar} />

                    <div className='flex flex-col justify-center w-full min-w-0'>
                        <span className='font-medium truncate text-wrap'>{data.title}</span>
                        <span className='text-xs'>
                            <strong className='mr-2 font-semibold'>Besteld:</strong>
                            <span className='text-gray-500'>
                                {currentOrder.orderDate && formatDate(currentOrder.orderDate)}
                            </span>
                        </span>
                    </div>
                </div>

                {/*
                    Address stays always the same
                    Regardless of amount of products
                */}
                <div className='flex items-center space-x-3 text-sm w-full md:w-6/12 lg:w-3/12 pl-8 md:pl-0'>
                    <IconWithBackground icon={faHouse} size='small' />
                    <div className='flex flex-col'>
                        <span className='truncate'>
                            {currentOrder.address?.street} {currentOrder.address?.houseNumber}
                            {currentOrder.address?.extension && ` (${currentOrder.address?.extension})`}
                        </span>
                        <span>
                            {currentOrder.address?.zipcode} {currentOrder.address?.city}
                        </span>
                    </div>
                </div>

                {/*  Order status - depending on current state  */}
                {showPhase && (
                    <div className='w-6/12 lg:w-2/12 pl-8 lg:pl-0'>
                        <OrderPhaseLabel
                            // phase={phase}
                            phase={currentOrder.phase}
                        />
                    </div>
                )}

                {/* Appointments */}
                <div className='w-6/12 lg:w-2/12 pl-8 lg:pl-0 flex justify-start'>
                    <AppointmentIndicator
                        // appointments={appointments}
                        appointments={currentOrder.appointments || undefined}
                    />
                </div>

                {actionButtons && (
                    <div className='w-4/12 lg:w-2/12 flex ml-auto justify-end'>
                        {actionButtons.indexOf('cancel') > -1 && (
                            <button className='bg-tibi-primary hover:bg-opacity-10 transition-colors duration-200 bg-opacity-5 border border-tibi-primary border-opacity-10 text-tibi-primary text-sm w-8 h-8 rounded-full'>
                                <FontAwesomeIcon icon={faUndo} />
                            </button>
                        )}
                        {actionButtons.indexOf('startThread') > -1 && (
                            <StartThreadButton
                                disabled={loading}
                                options={threadOptions}
                                onChange={handleStartThread}
                            />
                        )}
                    </div>
                )}
            </div>

            {/* HIDDEN PRODUCT LIST */}
            {open && (
                <div className='flex w-full flex-col mt-4 border-t border-t-tibi-fadedPrimary pt-2'>
                    {currentOrder.products?.map((product: OrderLine) => {
                        return (
                            <div key={product.id} className='pl-5'>
                                <ProductItem size='small' product={product as Product} showOptions key={product.id} />
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default OrderItem
