import React from 'react'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink, Outlet } from 'react-router-dom'
import Button from '../../components/Button/Button'
import OrderTabs from '../../components/OrderTabs/OrderTabs'
import TextSectionHeader from '../../components/TextSectionHeader'

const Orders: React.FunctionComponent = () => {
    return (
        <div>
            <div className='flex justify-between items-center my-2 flex-wrap gap-4 mb-4'>
                <TextSectionHeader>Mijn opdrachten</TextSectionHeader>
                <Button>
                    <NavLink to={'/dashboard/new-order'}>
                        <FontAwesomeIcon icon={faPlus} className='mr-2' />
                        Nieuwe opdracht
                    </NavLink>
                </Button>
            </div>
            <div className='flex flex-col space-y-4 bg-white border rounded-md border-tibi-fadedPrimary'>
                <OrderTabs />
                <Outlet />
            </div>
        </div>
    )
}

export default Orders
