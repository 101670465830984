import { DASHBOARD_ROUTES } from '../../helpers/constants'
import { NavLink, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-regular-svg-icons'
import tibiLogo from '../../images/TibiLogo.svg'
import { useDeliveries } from '../../hooks/useDeliveries'

interface AsideNaveMobileProps {
    openSidebarNav: boolean
    setOpenSidebarNav: (value: boolean) => void
}

const AsideNaveMobile = ({ openSidebarNav, setOpenSidebarNav }: AsideNaveMobileProps) => {
    const navigate = useNavigate()
    const { getNumNewDeliveries } = useDeliveries()

    if (!openSidebarNav) {
        return null
    }
    return (
        <div className='fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-20 z-50' onClick={() => setOpenSidebarNav(!openSidebarNav)}>
            <nav className='fixed z-50 top-0 left-0 h-screen w-[300px] border-r border-tibi-fadedPrimary sm:hidden bg-white p-4 space-y-4'
                 onClick={(e) => e.stopPropagation()}
            >
                <div className='flex items-center gap-x-4 border-b border-tibi-fadedPrimary border-opacity-60 pb-4'>
                    <button type="button" onClick={() => setOpenSidebarNav(false)}>
                        <FontAwesomeIcon icon={faBars} />
                    </button>

                    <button type='button'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/dashboard')
                                setOpenSidebarNav(false)
                            }}
                    >
                        <img className='h-[45px] object-cover' src={tibiLogo} alt='TibiMedia logo' />
                    </button>
                </div>

                <ul>
                    {DASHBOARD_ROUTES.map((route, i) => {
                        return (
                            <li key={i}>
                                <NavLink
                                    to={route.to}
                                    end={route.to === ''}
                                    className={({ isActive }) =>
                                        isActive ? 'text-black relative activeNavLink' : 'relative'
                                    }
                                    onClick={() => setOpenSidebarNav(false)}
                                >
                                    <div className='flex flex-row items-center pl-4 relative'>
                                        {route.icon && (
                                            <span className='mr-3 w-5 flex items-center justify-center'>
                                                <FontAwesomeIcon icon={route.icon} />
                                            </span>
                                        )}
                                        <span
                                            className={`${
                                                i === DASHBOARD_ROUTES.length - 1 ? 'border-none' : 'border-b'
                                            } flex w-full py-3 border-tibi-fadedPrimary items-center gap-x-2`}
                                        >
                                            {route.displayName}

                                            {route.displayName === 'Opleveringen' && getNumNewDeliveries() > 0 && (
                                                <div className='rounded-full w-6 h-6 flex items-center justify-center bg-tibi-primary text-white text-[10px] font-bold'>{getNumNewDeliveries()}</div>
                                            )}
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </div>
    )
}

export default AsideNaveMobile
