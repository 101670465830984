import { useState, Suspense, useEffect } from 'react'
// Import necessary FontAwesomeIcon component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxTaped } from '@fortawesome/pro-light-svg-icons'

const iconMap = new Map<string, () => Promise<any>>([
    ['fa-camera', () => import('@fortawesome/pro-light-svg-icons/faCamera')],
    ['fa-house', () => import('@fortawesome/pro-light-svg-icons/faHouse')],
    ['fa-building', () => import('@fortawesome/pro-light-svg-icons/faBuilding')],
    ['fa-coffee', () => import('@fortawesome/pro-light-svg-icons/faCoffee')],
    ['fa-user', () => import('@fortawesome/pro-light-svg-icons/faUser')]
    // Add more icons as needed
])

const DynamicFaIcon = ({ iconName, ...restProps }: { iconName?: string; [key: string]: any }) => {
    const [icon, setIcon] = useState<any>(null)

    // Function to dynamically load the icon
    // Function to dynamically load the icon based on the iconName prop
    const loadIcon = async (iconName: string) => {
        try {
            const iconLoader = iconMap.get(iconName)
            if (!iconLoader) {
                throw new Error(`Icon ${iconName} not found`)
            }

            const iconModule = await iconLoader()

            // Set the icon dynamically
            setIcon(iconModule)
        } catch (error) {
            console.error('Error loading icon:', error)
        }
    }

    // Effect to load the icon when the component mounts or when iconName changes
    useEffect(() => {
        if (iconName) {
            loadIcon(iconName)
        }
    }, [iconName])

    return icon ? (
        <Suspense fallback={<FontAwesomeIcon icon={faBoxTaped} />}>
            <FontAwesomeIcon icon={icon.definition} {...restProps} />
        </Suspense>
    ) : null
}

export default DynamicFaIcon
