import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader'
import AsideNav from '../../components/AsideNav/AsideNav'
import AsideNaveMobile from '../../components/AsideNav/AsideNaveMobile'

export interface DashboardProps {}

const Dashboard: React.FunctionComponent<DashboardProps> = () => {
    const [openSidebarNav, setOpenSidebarNav] = useState(false)

    return (
        <div className='w-full flex flex-col flex-grow text-tibi-text bg-tibi-blushPrimary'>
            <DashboardHeader openSidebarNav={openSidebarNav} setOpenSidebarNav={setOpenSidebarNav} />
            <div className='xl:container p-4 mx-auto flex-grow flex flex-col'>
                <div className='flex flex-row flex-grow'>
                    <AsideNav />
                    <AsideNaveMobile openSidebarNav={openSidebarNav} setOpenSidebarNav={setOpenSidebarNav} />
                    <div className='flex flex-grow flex-col'>
                        {/* MAIN CONTENT */}
                        {/* DISPLAY PANELS HERE */}
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}

// export default withAuthenticator(Dashboard, {
//     components: {},
// });
export default Dashboard
