import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Appointment from '../../components/Appointment/Appointment'
import SearchInput from '../../components/SearchInput/SearchInput'
import TextSectionHeader from '../../components/TextSectionHeader'
import { getAppointments } from '../../services/api/appointments'
import { APPOINTMENT_STATES } from '../../helpers/constants'
import DefaultLoader from '../../components/Loaders/DefaultLoader'
import { OrderAppointment, OrderAppointmentState } from '../../types'

interface SearchOptions {
    state?: OrderAppointmentState | undefined
    searchString?: string | undefined
}

const defaultSearchOptions: SearchOptions = {
    state: undefined,
    searchString: ''
}

const Appointments: React.FunctionComponent = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchOptions, setSearchOptions] = useState<SearchOptions>({
        ...defaultSearchOptions
    })
    const [appointments, setAppointments] = useState<OrderAppointment[]>([])

    useEffect(() => {
        const fetchAppointments = async () => {
            setLoading(true)

            try {
                const res = await getAppointments(searchOptions)
                setAppointments(res.items || [])
                setLoading(false)
            } catch (e) {
                console.error(e)
                setLoading(false)
            }
        }

        let ctrl: AbortController = new AbortController()
        fetchAppointments()
        return () => ctrl.abort()
    }, [searchOptions])

    /** Listen to search params change */
    useEffect(() => {
        setSearchOptions({
            ...searchOptions,
            state: (searchParams.get('state') as OrderAppointmentState) || undefined,
            searchString: searchParams.get('s') || undefined
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])

    const fetchAppointments = async () => {
        setLoading(true)

        try {
            const res = await getAppointments(searchOptions)
            setAppointments(res.items || [])
            setLoading(false)
        } catch (e) {
            console.error(e)
            setLoading(false)
        }
    }

    const handleSearchChange = (query: string) => {
        let params: { state?: string; s?: string } = { s: query }

        if (searchOptions.state) {
            params.state = searchOptions.state
        }
        // @ts-ignore
        setSearchParams(params)
    }

    const handleStatusChange = (item: any) => {
        let params: { state?: string; s?: string } = { state: item ? item.value : undefined }

        if (searchOptions.searchString) {
            params.s = searchOptions.searchString
        }
        // @ts-ignore
        setSearchParams(params)
    }

    return (
        <div>
            <div className='flex justify-between mb-4 items-center'>
                <TextSectionHeader>Afspraken</TextSectionHeader>
            </div>
            <div className='bg-white rounded-md border border-tibi-fadedPrimary p-4'>
                {/* Searchbar */}
                <div className='flex flex-wrap gap-4'>
                    <SearchInput
                        initialValue={searchOptions.searchString}
                        placeholder='Zoek op opdracht of locatie'
                        onSearchChange={handleSearchChange}
                        className='mr-4'
                    />
                    <Select
                        value={APPOINTMENT_STATES.find((s) => s.value === searchOptions.state) || null}
                        onChange={handleStatusChange}
                        options={APPOINTMENT_STATES}
                        placeholder='Alle statussen'
                        className='relative'
                    />
                </div>
                <div>
                    {/* LOADER */}
                    {loading ? (
                        <div className='w-full flex justify-center py-4'>
                            <DefaultLoader />
                        </div>
                    ) : (
                        appointments.map((app: OrderAppointment) => {
                            return <Appointment key={app.id} data={app} fetchAppointments={fetchAppointments} />
                        })
                    )}
                </div>
            </div>
        </div>
    )
}

export default Appointments
