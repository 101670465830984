import { Amplify } from 'aws-amplify'
import { signIn, signUp, type SignInInput } from 'aws-amplify/auth'

Amplify.configure({
    Auth: {
        Cognito: {
            // REQUIRED - Amazon Cognito Region
            // region: process.env.REACT_APP_AWS_AMPLIFY_REGION || '',

            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID || '',

            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID || ''
        }
    }
})

export async function logIn({ username, password }: SignInInput) {
    // const user = await Auth.signIn('testklant@tibimedia.nl', "test");
    return signIn({
        username,
        password,
        options: {
            authFlowType: 'USER_PASSWORD_AUTH'
        }
    })
}

type SignUpParameters = {
    username: string
    password: string
}

export async function register(params: SignUpParameters) {
    const { username, password } = params

    try {
        const user = await signUp({ username, password })
        return user
    } catch (error) {
        console.log('error signing up', error)
    }
}

// signIn()

// You can get the current config object
export const currentConfig = Amplify.getConfig()
