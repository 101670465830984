import React from 'react'
import { useLocalStorage } from 'react-use'

const initialContext: {
    value: any
    setValue(data: any): void
    remove(): void
} = {
    value: { user: null, deliveries: null },
    setValue: (data: any) => {},
    remove: () => {}
}
export const TibiContext = React.createContext(initialContext)

export const TibiContextProvider = ({ storageKey, children }: { storageKey: string; children: any }) => {
    const [value, setValue, remove] = useLocalStorage(storageKey)
    return (
        <TibiContext.Provider
            value={{
                value,
                setValue,
                remove
            }}
        >
            {children}
        </TibiContext.Provider>
    )
}
