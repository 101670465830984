/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { retrieveAddress } from '../../services/api/orders'
import { Address } from '../../types'
import ErrorMessage from '../ErrorMessage/ErrorMessage'

interface AddressFormProps {
    defaultValues?: Address
    onChange(values: any, isValid: boolean): void
}

const AddressForm: React.FunctionComponent<AddressFormProps> = ({ defaultValues, onChange }: AddressFormProps) => {
    const { register, getValues, setValue, formState, trigger } = useForm({
        defaultValues: defaultValues,
        reValidateMode: 'onChange'
    })
    const [loadingAddress, setLoadingAddress] = useState(false)

    useEffect(() => {
        onChange(getValues(), formState.isValid)
    }, [formState.isValid])

    const tryAdressAutocomplete = async () => {
        const { zipcode, houseNumber, extension, city, street } = getValues()

        trigger(['zipcode', 'houseNumber'])

        if (!zipcode || !houseNumber) {
            return
        }
        setLoadingAddress(true)
        try {
            // get autocomplete results
            const res = await retrieveAddress({ zipcode, houseNumber, extension })
            // Only overwrite values if not already filled
            if (res.city && !city) {
                setValue('city', res.city)
            }
            trigger('city')
            if (res.street && !street) {
                setValue('street', res.street)
            }
            trigger('street')
        } catch (e) {
            console.log(e)
        }
        setLoadingAddress(false)
    }

    return (
        <form className='flex flex-col w-full md:w-1/2 space-y-2'>
            <h2 className='mb-3 text-tibi-mainHeading font-medium text-lg'>Adresgegevens</h2>
            <div className='flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4 mb-1'>
                <div className='w-full flex-grow'>
                    <label className='text-sm font-medium text-tibi-mainHeading mb-1 block'>Postcode</label>
                    <input
                        {...register('zipcode', { required: 'Postcode is verplicht' })}
                        placeholder='Postcode'
                        type={'text'}
                        className='w-full'
                        onBlur={() => tryAdressAutocomplete()}
                        disabled={loadingAddress}
                    />
                    <ErrorMessage message={formState.errors.zipcode?.message} />
                </div>
                <div className='flex-grow w-full'>
                    <label className='text-sm font-medium text-tibi-mainHeading mb-1 block'>Huisnummer</label>
                    <input
                        {...register('houseNumber', { required: 'Huisnummer is verplicht', valueAsNumber: true })}
                        placeholder='Huisnummer'
                        type={'number'}
                        className='w-full rounded focus:outline-none px-3 py-1.5'
                        min={1}
                        onBlur={() => tryAdressAutocomplete()}
                        disabled={loadingAddress}
                    />
                    <ErrorMessage message={formState.errors.houseNumber?.message} />
                </div>
                <div className='flex-shrink'>
                    <label className='text-sm font-medium text-tibi-mainHeading mb-1 block'>Extensie</label>
                    <input
                        {...register('extension')}
                        placeholder='A'
                        type={'text'}
                        className='w-full'
                        onBlur={() => tryAdressAutocomplete()}
                        disabled={loadingAddress}
                    />
                </div>
            </div>
            <label className='text-sm font-medium text-tibi-mainHeading mb-1 block'>Straat</label>
            <input
                {...register('street', { required: 'Straat is verplicht' })}
                disabled={loadingAddress}
                placeholder='Voorbeeldlaan'
                type={'text'}
                className='mb-1'
            />
            <ErrorMessage message={formState.errors.street?.message} />
            <label className='text-sm font-medium text-tibi-mainHeading mb-1 block'>Plaats</label>
            <input
                {...register('city', { required: 'Plaats is verplicht' })}
                disabled={loadingAddress}
                placeholder='Voorbeeldplaats'
                type={'text'}
                className='mb-1'
            />
            <ErrorMessage message={formState.errors.city?.message} />
        </form>
    )
}

export default AddressForm
