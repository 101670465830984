import React, { useContext } from 'react'
import tibiLogo from '../../images/TibiLogo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { faAngleDown, faBars } from '@fortawesome/pro-regular-svg-icons'
import { Tooltip } from 'react-tooltip'
import { useNavigate } from 'react-router-dom'
import Avatar from '../Avatar/Avatar'
import { TibiContext } from '../../context/ContextProvider'

interface DashboardHeaderProps {
    openSidebarNav: boolean
    setOpenSidebarNav: (value: boolean) => void
}

const DashboardHeader: React.FunctionComponent<DashboardHeaderProps> = ({
    openSidebarNav,
    setOpenSidebarNav
}: DashboardHeaderProps) => {
    //Auth hook for components
    const { signOut } = useAuthenticator()
    const { value, remove }: any = useContext(TibiContext)
    const user = value?.user

    const navigate = useNavigate()

    return (
        <header className='bg-white border-b border-tibi-fadedPrimary border-opacity-60'>
            <div className='flex flex-wrap items-center justify-between sticky top-0 bg-white z-50 p-4 mx-auto xl:container'>
                <div className='flex items-center'>
                    <button type='button' className='mr-4 sm:hidden' onClick={() => setOpenSidebarNav(!openSidebarNav)}>
                        <FontAwesomeIcon icon={faBars} />
                    </button>

                    {/* Logo */}
                    <button type='button' onClick={(e) => {e.preventDefault(); navigate('/dashboard')}}><img className='w-full h-[45px] object-cover' src={tibiLogo} alt='TibiMedia logo' /></button>
                </div>

                <div>
                    <div>{/* Notification */}</div>
                    <button id='userDropdown' className='space-x-2 flex items-center cursor-pointer'>
                        {/* User */}
                        <Avatar name={user?.name} />
                        <span className='capitalize font-semibold'>{user?.name}</span>
                        <FontAwesomeIcon icon={faAngleDown} className='text-gray-400' />
                    </button>
                    <Tooltip
                        anchorSelect='#userDropdown'
                        variant='light'
                        clickable
                        className='bg-white p-0 m-0 rounded-lg shadow-lg bg-opacity-100'
                    >
                        <button
                            onClick={async () => {
                                await signOut()
                                remove()
                                navigate('/login')
                            }}
                            className=' text-tibi-text px-4 py-2 font-semibold cursor-pointer hover:text-tibi-mainHeading'
                        >
                            Uitloggen
                        </button>
                    </Tooltip>
                </div>
            </div>
        </header>
    )
}

export default DashboardHeader
