import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { MouseEventHandler } from 'react'

export interface IconButtonProps {
    icon: any
    onClick: MouseEventHandler
    type?: 'button' | 'submit' | 'reset' | undefined
    className?: string
    iconClassName?: string
    disabled?: boolean
}

const IconButton: React.FC<IconButtonProps> = ({
    icon,
    type = 'button',
    className = '',
    iconClassName = '',
    onClick,
    disabled = false,
}: IconButtonProps) => {
    const defaultClasses = 'flex items-center justify-center rounded-full w-10 h-10 disabled:opacity-50 disabled:cursor-not-allowed hover:opacity-75 transition-opacity bg-tibi-primary text-white'

    return (
        <button type={type}
                className={defaultClasses + ' ' + className}
                onClick={onClick}
                disabled={disabled}
        >
            <FontAwesomeIcon className={iconClassName} icon={icon} />
        </button>
    )
}

export default IconButton
