// import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import IconWithBackground from '../IconWithBackground/IconWithBackground'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Product, ProductOption } from '../../types'
import Modal from '../Modal/Modal'
import { formatCurrencyValue } from '../../helpers/utils'

export interface ExtendedProductOption extends ProductOption {
    _isAdded?: boolean
}

export interface ExtendedProduct extends Product {
    _isPack?: boolean
    availableOptions?: ExtendedProductOption[]
}

interface ProductOptionsModalContentProps {
    product?: ExtendedProduct
    onRequestClose?(): void
    onConfirm?(product: ExtendedProduct | undefined, selectedOptions: ProductOption[]): void
}

const ProductOptionsModalContent: React.FunctionComponent<ProductOptionsModalContentProps> = ({
    product,
    onRequestClose,
    onConfirm
}: ProductOptionsModalContentProps) => {
    const [selectedOptions, setSelectedOptions] = useState<ProductOption[]>([])

    const initializeOptions = () => {
        if (!selectedOptions.length) {
            setSelectedOptions(product?.availableOptions?.filter((option) => option._isAdded) || [])
        }
    }

    const handleConfirm = () => {
        onConfirm && onConfirm(product, selectedOptions)
    }

    const handleClick = (option: ProductOption) => {
        const isSelected = selectedOptions.map((o) => o.id).includes(option.id)
        if (isSelected) {
            setSelectedOptions(selectedOptions.filter((selectedOption) => selectedOption.id !== option.id))
        } else {
            setSelectedOptions([...selectedOptions, option])
        }
    }

    useEffect(() => {
        // resetProductList()
        initializeOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal buttonText='Bevestigen' onRequestClose={onRequestClose} onConfirm={handleConfirm}>
            <div className='px-4 pt-3 pb-0 bg-tibi-primary/5'>
                <div className='flex items-center gap-x-2 pb-3 border-b-2'>
                    <IconWithBackground iconName={product?.icon} />

                    <div className='text-tibi-text text-left'>
                        <p className='font-medium'>{product?.name}</p>
                        <p className='text-xs text-opacity-50'>{product?.description}</p>
                    </div>
                </div>
            </div>

            <div className='p-6 text-left flex flex-col gap-y-4'>
                <span className='text-black font-semibold'>Opties</span>

                <ul className='flex flex-col gap-y-4'>
                    {product?.availableOptions?.map((option: ProductOption) => {
                        const isSelected = selectedOptions.map((o) => o.id).includes(option.id)
                        return (
                            <li key={option.id}>
                                <label
                                    htmlFor={`product-option-${option.id}`}
                                    className='flex gap-x-3 my-0 items-start'
                                >
                                    <input
                                        type='checkbox'
                                        name={`product-option-${option.id}`}
                                        id={`product-option-${option.id}`}
                                        className='hidden'
                                        onChange={() => handleClick(option)}
                                    />
                                    <div
                                        className={
                                            'border rounded w-5 h-5 mt-0.5 flex justify-center items-center text-xs text-white ' +
                                            (isSelected ? ' bg-tibi-green' : '')
                                        }
                                    >
                                        {isSelected && <FontAwesomeIcon icon={faCheck} />}
                                    </div>
                                    <span>
                                        {option.name} ({formatCurrencyValue(option.price)})
                                    </span>
                                </label>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </Modal>
    )
}

export default ProductOptionsModalContent
