import { faMessage, faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select, { type DropdownIndicatorProps, components, SingleValue } from 'react-select'

interface StartThreadButtonProps {
    options: any[]
    onChange(newValue: SingleValue<any>): void
    disabled?: boolean
}

const StartThreadButton = ({ onChange, options, disabled = false }: StartThreadButtonProps) => {
    const customControlStyles = (base: any) => ({
        ...base,
        fontSize: '0.9rem',
        minHeight: 35,
        color: 'rgb(199,85, 85)',
        backgroundColor: 'rgb(255,247,247)',
        borderColor: 'rgba(199,85, 85, 0.2)'
    })

    const customCaretStyles = (base: any) => ({
        ...base,
        color: 'rgb(199,85, 85)'
    })

    const customMenuStyles = (base: any) => ({
        ...base,
        width: 'max-content',
        fontSize: '0.9rem',
        minWidth: '100%',
        right: 0
    })

    const CaretDownIcon = () => {
        return <FontAwesomeIcon icon={faPlus} />
    }

    // added type if using typescript
    const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon />
            </components.DropdownIndicator>
        )
    }

    return (
        <Select
            value={''}
            onChange={(newValue: SingleValue<any>) => onChange(newValue)}
            options={options}
            isDisabled={disabled}
            defaultValue={''}
            menuPlacement='auto'
            menuPosition='fixed'
            placeholder={
                <span className={'text-tibi-primary flex items-center'}>
                    <FontAwesomeIcon className='text-tibi-primary mr-2' icon={faMessage} /> <span>Contact</span>
                </span>
            }
            className='relative'
            components={{ DropdownIndicator }}
            isSearchable={false}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary25: 'rgba(199, 85, 85, 0.2)',
                    primary: 'rgb(199, 85, 85)'
                }
            })}
            styles={{ control: customControlStyles, dropdownIndicator: customCaretStyles, menu: customMenuStyles }}
        />
    )
}

export default StartThreadButton
