import ReactPaginate from 'react-paginate'

const Pagination = ({
    page,
    totalItems,
    onPageChange
}: {
    page: number
    totalItems: number
    onPageChange(page: number): void
}) => {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const itemsPerPage: number = 10

    const calculatePageCount = () => {
        const base = Math.ceil(totalItems / itemsPerPage)
        const number = base === 0 ? base + 1 : base
        return number
    }

    return (
        <ReactPaginate
            initialPage={page - 1}
            breakLabel='...'
            nextLabel='&raquo;'
            onPageChange={({ selected }) => onPageChange(selected + 1)}
            pageRangeDisplayed={5}
            pageCount={calculatePageCount()}
            previousLabel='&laquo;'
            previousClassName={'text-gray-700 hover:text-gray-900'}
            previousLinkClassName={'font-sans block font-light appearance-none rounded px-3 py-2 mr-2'}
            nextClassName={'text-gray-700 hover:text-gray-900 pr-10'}
            nextLinkClassName={'font-sans block font-light appearance-none rounded px-3 py-2 ml-2'}
            activeLinkClassName={'rounded border text-black font-semibold border-tibi-primary'}
            breakClassName={'break-me'}
            disabledClassName={'opacity-50 cursor-not-allowed'}
            pageLinkClassName={'font-sans block font-light appearance-none mx-1 px-3 py-1 text-gray-600'}
            marginPagesDisplayed={2}
            disableInitialCallback={true}
            containerClassName={'flex justify-center items-center my-4'}
            activeClassName={'active'}
            // renderOnZeroPageCount={null}
        />
    )
}

export default Pagination
