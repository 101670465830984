import Modal from "../Modal/Modal"

interface ConfirmModalProps {
    title: string
    subtitle?: string
    primaryButtonText: string
    secondaryButtonText?: string
    onRequestClose?: () => void
    onConfirm?: () => void
}

const ConfirmModalContent: React.FunctionComponent<ConfirmModalProps> = ({
    title, subtitle, primaryButtonText, secondaryButtonText, onRequestClose, onConfirm
}: ConfirmModalProps) => {
    return (
        <Modal buttonText={primaryButtonText} onRequestClose={onRequestClose} onConfirm={onConfirm}>
            <div className='p-6'>
                <p className='text-xl font-bold'>{title}</p>
                <p className='pt-4'>{subtitle}</p>
                <button type='button' className='underline absolute bottom-0 left-0 ml-4 mb-6' onClick={onRequestClose}>{secondaryButtonText}</button>
            </div>
        </Modal>
    )
}

export default ConfirmModalContent
