import React, { useState, useRef } from 'react'
import { useDebounce, usePrevious } from 'react-use'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface SearchInputProps {
    placeholder: string
    className?: string
    initialValue?: string | undefined
    onSearchChange(query: string): void
}

const SearchInput: React.FunctionComponent<SearchInputProps> = ({
    placeholder,
    className,
    initialValue,
    onSearchChange
}: SearchInputProps) => {
    const [val, setVal] = useState(initialValue || '')
    const previousVal = usePrevious(val)
    const inputRef = useRef<HTMLInputElement>(null)

    const [, cancel] = useDebounce(
        () => {
            if (val !== previousVal) {
                onSearchChange(val)
            }
        },
        500,
        [val]
    )

    const handleKeyDown = (e: any) => {
        if (e.keyCode === 27) {
            e.preventDefault()
            setVal('')
            inputRef.current && inputRef.current.blur()
        }
    }

    return (
        <div className={`border rounded flex justify-between items-center bg-white ${className}`}>
            <input
                type='search'
                className='w-full rounded'
                ref={inputRef}
                placeholder={placeholder}
                value={val}
                onKeyDown={handleKeyDown}
                onBlur={cancel}
                onChange={({ currentTarget }) => {
                    setVal(currentTarget.value)
                }}
            />
            <FontAwesomeIcon icon={faSearch} className='text-gray-400 pr-2' />
        </div>
    )
}

export default SearchInput
