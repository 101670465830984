import { get } from './api'
import { InlineResponse200 } from '../../types'

export const getOrderDeliveries = async (params?: {
    searchString?: string
    page?: number
    pageSize?: number
}): Promise<InlineResponse200> => {
    const { data } = await get('/order-deliveries', {
        params: {
            ...params
        }
    })

    return data
}

export const getOrderDeliveryDownloadLink = async (deliveryId: number) => {
    const { data } = await get(`/order-deliveries/${deliveryId}/download-link`)
    return data
}
