import React, {ReactNode} from 'react'

interface Props {
    children: ReactNode,
}

function TextSectionHeader({children}: Props) {
  return (
    <h2 className="text-2xl font-semibold text-black mb-2">
        {children}
    </h2>
  )
}

export default TextSectionHeader