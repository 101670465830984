/* eslint-disable react-hooks/exhaustive-deps */
import { createPortal } from 'react-dom'
import { memo, useEffect, useRef, useState } from 'react'

interface BodyPortalProps {
    id: string
    children: React.ReactNode
}

const BodyPortal: React.FunctionComponent<BodyPortalProps> = ({ id, children }: BodyPortalProps) => {
    const isBrowser = () => typeof window !== 'undefined'

    const el = useRef(isBrowser() ? document.getElementById(id) || document.createElement('div') : null)
    const [dynamic] = useState(el && el.current ? !el.current.parentElement : false)

    useEffect(() => {
        if (!isBrowser) return

        if (dynamic && el.current) {
            el.current.id = id
            document.body.appendChild(el.current)
        }
    }, [id])

    // @ts-ignore
    return createPortal(children, el.current)
}

export default memo(BodyPortal)
