import { useContext, useEffect } from 'react'
import { OrderDelivery } from '../types'
import { TibiContext } from '../context/ContextProvider'
import { getOrderDeliveries } from '../services/api/deliveries'

export const useDeliveries = () => {
    const { value, setValue } = useContext(TibiContext)

    const deliveryList: OrderDelivery[] = value.deliveries || []

    useEffect(() => {
        if (!value.deliveries) {
            getDeliveries(10)
        }
    }, [])

    const getDeliveries = async (size: number = 10) => {
        const res = await getOrderDeliveries({ page: 1, pageSize: size })
        setDeliveries(res.items)
    }

    const setDeliveries = (data: OrderDelivery[]) => {
        setValue({ ...value, deliveries: data })
    }

    const getNumNewDeliveries = () => {
        if (!value.deliveries) return 0
        return value.deliveries.filter((delivery: OrderDelivery) => !delivery.downloaded).length
    }

    return { deliveryList, getDeliveries, setDeliveries, getNumNewDeliveries }
}
