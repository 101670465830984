import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

interface DefaultLoaderProps {
    loadingMessage?: string
}

const DefaultLoader: React.FunctionComponent<DefaultLoaderProps> = ({ loadingMessage }: DefaultLoaderProps) => {
    return (
        <div className='flex flex-col space-y-4 items-center justify-center'>
            <FontAwesomeIcon icon={faSpinner} className='text-3xl text-tibi-primary' spin />
            <span className='text-sm'>{loadingMessage || 'Loading'}</span>
        </div>
    )
}

export default DefaultLoader
