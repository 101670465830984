import { faCalendar, faRuler } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { OrderAppointment } from '../../types'
import AppointmentStateLabel from '../AppointmentStateLabel/AppointmentStateLabel'
import IconWithBackground from '../IconWithBackground/IconWithBackground'
import { formatDate } from '../../helpers/utils'
import ActionButton, { ActionOption } from '../ActionButton/ActionButton'
import { confirmAppointment, rejectAppointment } from '../../services/api/appointments'

interface AppointmentProps {
    data: OrderAppointment
    fetchAppointments: () => void
}

const Appointment: React.FunctionComponent<AppointmentProps> = ({ data, fetchAppointments }: AppointmentProps) => {
    const handleConfirmAppointment = async () => {
        try {
            await confirmAppointment(data.id)
            fetchAppointments()
        } catch (e) {
            console.error(e)
        }
    }

    const handleRejectAppointment = async () => {
        try {
            await rejectAppointment(data.id)
            fetchAppointments()
        } catch (e) {
            console.error(e)
        }
    }

    const options: ActionOption[] = []
    if (data.state === 'proposed') {
        options.push({ text: 'bevestigen', action: handleConfirmAppointment,  })
    }
    if (data.state !== 'cancelled') {
        options.push({ text: 'annuleren', type: 'danger', action: handleRejectAppointment })
    }

    return (
        <div className='w-full flex flex-wrap items-center px-4 border-b py-3 gap-5 lg:gap-0'>
            <div className='flex items-start space-x-4 basis-2/12'>
                {/* DATE */}
                <FontAwesomeIcon className='text-xl mt-1' icon={faCalendar} />
                <div className='flex flex-col'>
                    <span className='font-medium mb-1'>{data.concerns}</span>
                    <span className='text-gray-500 text-xs'>{data.date && formatDate(data.date)}</span>
                </div>
            </div>
            <div className='basis-2/12'>
                {/* WHO */}
                <span className='text-sm text-tibi-linkColor font-medium'>{data.concerns}</span>
            </div>
            <div className='basis-2/12'>
                <AppointmentStateLabel state={data.state} />
            </div>
            <div className='flex items-center space-x-3 basis-5/12'>
                {/* OPDRACHT */}
                <IconWithBackground icon={faRuler} />
                <div className='flex flex-col'>
                    <span className='font-semibold text-sm'>{data.order?.title}</span>
                    <span className='text-xs'>{`${data.order?.address}, ${data.order?.zipcode} ${data.order?.city}`}</span>
                </div>
            </div>
            <div className='basis-1/12 flex justify-end'>
                <ActionButton options={options} />
            </div>
        </div>
    )
}

export default Appointment
