import { faArrowRight, faMessage } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getMessageThreads } from '../../services/api/messages'
import { OrderMessageThread } from '../../types'
import Button from '../Button/Button'
import CategoryHeader from '../CategoryHeader'
import DefaultLoader from '../Loaders/DefaultLoader'
import NieuweBericht from '../MessageItem/MessageItem'

const MessagesWidget: React.FunctionComponent = () => {
    const [loading, setLoading] = useState(false)
    const [messageThreads, setMessageThreads] = useState<[] | any[]>([])
    const navigate = useNavigate()

    useEffect(() => {
        let ctrl: AbortController = new AbortController()
        const fetchMessageThreads = async () => {
            setLoading(true)
            try {
                const messages = await getMessageThreads()
                // setMessageThreads(messages)
                setMessageThreads(Array.isArray(messages) ? messages : [])
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
        }
        fetchMessageThreads()
        return () => ctrl.abort()
    }, [])

    return (
        <div className='space-y-4 flex-1 h-full'>
            <CategoryHeader title='Nieuwe berichten' icon={faMessage} />
            <div className='flex flex-col bg-white overflow-hidden border border-tibi-fadedPrimary rounded-md'>
                <div>
                    {loading ? (
                        <div className='w-full flex items-center justify-center py-8'>
                            <DefaultLoader />
                        </div>
                    ) : messageThreads.length <= 0 ? (
                        <div className='flex items-center justify-center text-gray-400 py-8'>Geen berichten</div>
                    ) : (
                        messageThreads.map((thread: OrderMessageThread) => {
                            return <NieuweBericht key={thread.id} data={thread} />
                        })
                    )}
                </div>
                {messageThreads.length > 0 && !loading && (
                    <div className='mr-auto p-4 px-6'>
                        <Button className='bg-white text-tibi-text border' onClick={() => navigate('messages')}>
                            Alle berichten
                            <FontAwesomeIcon icon={faArrowRight} className='ml-2' />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default MessagesWidget
