import { faArrowLeft, faArrowRight, faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import TextSectionHeader from '../TextSectionHeader'
import ProductSelection from './ProductSelection'
import OrderDetailSelection from './OrderDetailSelection'
import { Product, ProductCategoryDetail } from '../../types'
import Button from '../Button/Button'
import OrderSummary from './OrderSummary'
import { createOrder } from '../../services/api/orders'
// import PopularPackages from './PopularPackages'

// interface NewOrderState {
//     category: number
//     address: Address
//     products: Product[]
//     // surface: number
// }

const NewOrder: React.FunctionComponent = () => {
    //State
    const navigate = useNavigate()
    const [data, setData] = useState<any>({
        category: 0,
        address: {
            street: '',
            city: '',
            extension: '',
            zipcode: '',
            houseNumber: ''
        },
        // surface: 0,
        products: []
    })
    const [isFormValid, setIsFormValid] = useState(false)
    const [placingOrder, setPlacingOrder] = useState(false)
    const [selectedOrderCategory, setSelectedOrderCategory] = useState<ProductCategoryDetail>()
    const [currentStepIndex, setCurrentStepIndex] = useState(0)

    useEffect(() => {
        setData({ ...data, products: [] })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrderCategory])

    //Handlers
    const nextStep = () => {
        setCurrentStepIndex(currentStepIndex + 1)
    }

    const prevStep = () => {
        setCurrentStepIndex(currentStepIndex - 1)
    }

    const placeOrder = async () => {
        setPlacingOrder(true)

        try {
            const allOptions =
                data.products.length > 0 &&
                data.products
                    .filter((a: Product) => a.availableOptions)
                    .map((a: any) => a.availableOptions)
                    .flat()
                    .filter((a: any) => a._isAdded) // filter undefined values

            // get all product options that are added
            const productOptions = allOptions && allOptions.length > 0 && allOptions.map((a: any) => a.id)

            const res = await createOrder({
                category: Number(data.category),
                extension: data.address.extension,
                houseNumber: Number(data.address.houseNumber),
                zipcode: data.address.zipcode,
                packs: data.products.filter((a: any) => a._isPack).map((a: any) => a.id),
                products: data.products.filter((a: any) => !a._isPack).map((a: any) => a.id),
                remarks: data.remarks,
                productOptions: productOptions || []
            })

            if (res) {
                // Todo: show toast notificatio here that order is placed
                navigate('/dashboard/orders')
            }
        } catch (e) {
            console.log(e)
        }
        setPlacingOrder(false)
    }

    const handleFormChange = (formData: any, isValid: boolean) => {
        setData({ ...data, address: formData })
        setIsFormValid(isValid)
    }

    const onCategorySelect = (cat: ProductCategoryDetail) => {
        setSelectedOrderCategory(cat)
        setData({ ...data, category: cat.id })

        // Go directly to next step if no address data is requested
        // TODO prop exists in BE, but is not properly assigned yet
        // if (!cat.requestAddress) {
        //   nextStep();
        // }
    }

    const handleProductSelectionChange = (products: Product[]) => {
        setData({ ...data, products: products })
    }

    const handleRemarkChange = (remark: string) => {
        setData({ ...data, remarks: remark })
    }

    const removeProduct = (product: any) => {
        const currentList = [...data.products]
        // const productIndex = currentList.map((p: Product) => p.id).indexOf(product.id);
        // const productIndex = currentList.findIndex((a: any) => product._isPack ? product._isPack === true && product.id === a.id : product.id === a.id)
        const productIndex = currentList.findIndex((a: any) => product._isPack === a._isPack && a.id === product.id)
        currentList.splice(productIndex, 1)
        setData({ ...data, products: currentList })
    }

    const steps = [
        {
            displayName: 'Opdracht details',
            component: (
                <OrderDetailSelection
                    initialFormData={data.address}
                    handleFormChange={handleFormChange}
                    onCategorySelect={onCategorySelect}
                    selectedOrderCategory={selectedOrderCategory}
                />
            ),
            nextButton: selectedOrderCategory ? (
                <Button onClick={nextStep} disabled={!isFormValid}>
                    Volgende stap <FontAwesomeIcon icon={faArrowRight} className='ml-2' />
                </Button>
            ) : null
        },

        {
            displayName: 'Producten kiezen',
            component: (
                <ProductSelection
                    selectedProducts={data.products}
                    address={data.address}
                    categoryId={data.category}
                    onSelectionChange={handleProductSelectionChange}
                    onNext={nextStep}
                />
            ),
            prevButton: (
                <Button onClick={prevStep} className='bg-white text-tibi-text border'>
                    <FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
                    Vorige
                </Button>
            )
        },

        {
            displayName: 'Controleren & Plaatsen',
            component: <OrderSummary data={data} handleProductSelectionChange={handleProductSelectionChange} removeProduct={removeProduct} onRemarkChange={handleRemarkChange} />,
            prevButton: (
                <Button onClick={prevStep} className='bg-white text-tibi-text border'>
                    <FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
                    Vorige
                </Button>
            ),
            nextButton: (
                <Button disabled={!data.products.length || placingOrder} onClick={placeOrder}>
                    Plaats opdracht{' '}
                    <FontAwesomeIcon
                        icon={placingOrder ? faSpinner : faArrowRight}
                        spin={placingOrder}
                        className='ml-2'
                    />
                </Button>
            )
        }
    ]

    //Read
    const currentStepObject: any = steps[currentStepIndex]

    return (
        <div>
            <NavLink to='/dashboard/orders' className='mb-2 block'>
                <FontAwesomeIcon icon={faArrowLeft} className='mr-2 text-tibi-primary' />
                <span className='font-semibold underline'>Opdrachten</span>
            </NavLink>
            <div className='flex space-x-2 items-center justify-between mb-4'>
                <TextSectionHeader>Nieuwe opdracht</TextSectionHeader>
            </div>
            <div className='w-full flex gap-x-2 md:gap-x-0 md:justify-between'>
                {steps.map((step, stepIndex) => {
                    return (
                        <div key={stepIndex} className='flex items-center space-x-2'>
                            <div
                                className={`leading-none h-9 w-9 flex justify-center items-center border border-tibi-fadedPrimary ${
                                    currentStepIndex === stepIndex && 'bg-tibi-primary text-white border-none'
                                } font-semibold rounded-full`}
                            >
                                {stepIndex + 1}
                            </div>
                            <span className='font-medium hidden md:block'>{step.displayName}</span>
                        </div>
                    )
                })}
            </div>
            <div>{currentStepObject.component}</div>
            <div className='flex justify-end py-4'>
                {currentStepObject.prevButton && <div className='mr-auto'>{currentStepObject.prevButton}</div>}
                {currentStepObject.nextButton}
            </div>
        </div>
    )
}

export default NewOrder
