import React from 'react'
import { ORDER_PHASES } from '../../helpers/constants'
import { OrderPhase } from '../../types'

const OrderPhaseLabel = (props: { phase: OrderPhase | undefined }) => {
    const { phase: currentPhase } = props
    const phases = ORDER_PHASES.filter((p) => p.showInFilter)
    const phaseConfig = phases.find((p) => p.value === currentPhase)
    const phaseIndex = currentPhase ? phases.map((p) => p.value).indexOf(currentPhase) : 0
    return (
        <div className='flex flex-col'>
            <div className='bg-[#ECECEC] relative w-32 rounded-full overflow-hidden h-[6px]'>
                <div id='lopende-opdracht__status--divider' className='w-full h-full flex items-center absolute z-10'>
                    {new Array(phases.length - 1).fill(null).map((_each, index) => (
                        <div
                            key={index}
                            className={`flex h-full justify-end overflow-hidden leading-none text-white border-r-2 border-white`}
                            style={{
                                width: Math.floor(100 / phases.length) + '%'
                            }}
                        ></div>
                    ))}
                </div>
                <div
                    className='absolute top-0 left-0 h-full'
                    style={{
                        backgroundColor: phaseConfig?.hexColor,
                        width: Math.floor(100 / phases.length) * (phaseIndex + 1) + '%'
                    }}
                ></div>
            </div>
            <span className='text-xs block font-medium pt-1'>{phaseConfig?.label}</span>
        </div>
    )
}

export default OrderPhaseLabel
