import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import IconWithBackground from "../IconWithBackground/IconWithBackground"

interface DashboardNotificationProps {
    icon: IconDefinition
    children?: React.ReactNode
}

const DashboardNotification = ({ icon, children }: DashboardNotificationProps) => {
  return (
    <div className="border border-tibi-fadedPrimary bg-white rounded-lg flex items-center gap-x-2 p-2">
        <IconWithBackground icon={icon} size="small" />
        {children}
    </div>
  )
}

export default DashboardNotification
