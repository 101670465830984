/* tslint:disable */
/* eslint-disable */
/**
 * TibiMedia Customer API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: administratie@tibimedia.nl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderPhase {
    Active = 'active',
    Completed = 'completed',
    Archived = 'archived'
}

