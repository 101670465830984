import { get } from './api'

export const getCategories = async (query?: { searchString: string }) => {
    const { data } = await get('/categories', {
        params: {
            ...query
        }
    })
    return data
}

export const getCategoryProducts = async (query?: { categoryUUID: number }) => {
    const { data } = await get(`/categories/${query?.categoryUUID}`, {})
    return data
}
