/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import SearchInput from '../../components/SearchInput/SearchInput'
import DefaultLoader from '../../components/Loaders/DefaultLoader'
import DeliveryItem from './DeliveryItem'
import { getOrderDeliveries } from '../../services/api/deliveries'
import { SearchOptions } from '../../components/OrderOverview/OrderOverview'
import Pagination from '../../components/Pagination/Pagination'
import { OrderDelivery } from '../../types'

export interface Delivery {
    id: string
    created: string
    updated: string
    message: string
    order: any
    parent_order: any
}

const Deliveries: React.FunctionComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState<boolean>(false)

    const defaultSearchOptions = {
        page: 1,
        pageSize: 10,
        state: undefined,
        searchString: ''
    }

    const [searchOptions, setSearchOptions] = useState<SearchOptions>({
        ...defaultSearchOptions
    })

    const [deliveries, setDeliveries] = useState<OrderDelivery[]>([])
    const [total, setTotal] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(1)

    const getDeliveries = async () => {
        setLoading(true)

        const params = {
            ...searchOptions,
            page: currentPage
        }
        if (!params.searchString) {
            delete params.searchString
        }
        const res = await getOrderDeliveries(params)

        if (res && res.items.length) {
            // @ts-ignore
            setDeliveries(res.items.reverse())
            // @ts-ignore
            setTotal(res.count)
        }

        setLoading(false)
    }

    const handleSearchChange = (query: string) => {
        const params: { state?: string; s?: string } = {}

        if (query) {
            params.s = query
        }

        if (searchOptions.state) {
            params.state = searchOptions.state
        }

        setSearchParams(params)
    }

    useEffect(() => {
        getDeliveries()
    }, [])

    useEffect(() => {
        getDeliveries()
    }, [searchParams])

    useEffect(() => {
        setSearchOptions({
            ...searchOptions,
            // state: OrderState[searchParams.get('state') as keyof typeof OrderState] ?? undefined,
            searchString: searchParams.get('s') || undefined
        })
    }, [searchParams])

    return (
        <div className='border border-tibi-fadedPrimary bg-white rounded-lg'>
            <div className='flex flex-wrap px-6 pt-6'>
                <SearchInput
                    initialValue={searchOptions.searchString}
                    placeholder='Zoek op opdracht of locatie'
                    onSearchChange={handleSearchChange}
                    className='w-full max-w-lg'
                />
            </div>

            {loading ? (
                <div className='w-full flex justify-center py-4'>
                    <DefaultLoader />
                </div>
            ) : (
                <>
                    {total === 0 ? (
                        <div className='text-center text-gray-400 py-8'>Geen opleveringen</div>
                    ) : (
                        <div className='pt-4'>
                            {deliveries.map((delivery: OrderDelivery) => {
                                return <DeliveryItem key={delivery.id} data={delivery} onDownload={getDeliveries} />
                            })}
                        </div>
                    )}

                    {total > 0 && (
                        <div className='p-6'>
                            <Pagination
                                // @ts-ignore
                                page={currentPage}
                                totalItems={total}
                                onPageChange={(page: any) => {
                                    setCurrentPage(page)
                                }}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default Deliveries
