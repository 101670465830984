import Button from '../Button/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

export interface ModalProps {
    // isOpen: boolean
    buttonText?: string | React.ReactNode
    onRequestClose?(): void
    onConfirm?(): void
    children?: React.ReactNode
}

const Modal: React.FunctionComponent<ModalProps> = ({ buttonText, onRequestClose, onConfirm, children }) => {
    const handleConfirm = () => {
        onConfirm?.()
        onRequestClose?.()
    }

    return (
        <div
            className='fixed top-0 left-0 w-full h-full z-50 bg-black bg-opacity-30 text-center flex flex-col justify-center items-center'
            onClick={onRequestClose}
        >
            <div
                className='border rounded-lg bg-white relative w-full max-w-[500px] h-full max-h-[500px] flex-grow'
                onClick={(e) => e.stopPropagation()}
            >
                <button className='absolute top-0 right-0 m-4' onClick={onRequestClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>

                {children}

                <div className='absolute bottom-0 right-0 m-4'>
                    <Button onClick={handleConfirm}>{buttonText}</Button>
                </div>
            </div>
        </div>
    )
}

export default Modal
