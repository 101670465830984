import { FieldValues } from 'react-hook-form'
import { InlineResponse200, OrderPhase } from '../../types'
import { get, post, put } from './api'
import { ExtendedProductOption } from '../../components/ProductOptionsModalContent/ProductOptionsModalContent'

export const getOrders = async (query?: {
    searchString?: string
    phase?: OrderPhase | undefined
    page?: number
    pageSize?: number
}): Promise<InlineResponse200> => {
    const { data } = await get('/orders', {
        params: {
            ...query
        }
    })

    return data
}

export const retrieveAddress = async (params: FieldValues) => {
    const { data } = await put('/orders/retrieve-address', {
        data: params
    })

    return data
}

export const calculateTotalOrderPrice = async (params: {
    zipcode: string
    houseNumber: number
    extension?: string
    category: number
    packs?: number[]
    products?: number[]
    productOptions?: ExtendedProductOption[]
}) => {
    const { data } = await put('/orders/calculate', {
        data: params
    })

    return data
}

export const createOrder = async (params: {
    category: number
    zipcode: string
    houseNumber: number
    extension: string
    surface?: string
    packs?: number[]
    products?: number[]
    productOptions?: number[]
    remarks?: string
}) => {
    const { data } = await post('/orders', {
        data: params
    })

    return data
}
