import { get, put } from './api'

export const getAppointments = async (query?: { searchString?: string | undefined; state?: string | undefined }) => {
    const { data } = await get('/appointments', {
        params: {
            ...query
        }
    })
    return data
}

export const confirmAppointment = async (id: number | undefined) => {
    const { data } = await put(`/appointments/${id}/confirm`, {})
    return data
}

export const rejectAppointment = async (id: number | undefined) => {
    const { data } = await put(`/appointments/${id}/reject`, {})
    return data
}
