import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export interface CategoryHeaderProps {
    title: string
    icon?: IconProp
}

const CategoryHeader: React.FC<CategoryHeaderProps> = (props) => {
    return (
        <div className='text-tibi-text flex space-x-2 items-center'>
            {props.icon && <FontAwesomeIcon className='text-tibi-primary text-xl' icon={props.icon as IconProp} />}
            <h3 className='font-semibold text-xl text-tibi-mainHeading mb-0'>{props.title}</h3>
        </div>
    )
}

export default CategoryHeader
