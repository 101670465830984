import React, { useEffect, useState } from 'react'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getOrders } from '../../services/api/orders'
import DefaultLoader from '../Loaders/DefaultLoader'
import OrderItem from '../OrderItem/OrderItem'
import { useNavigate } from 'react-router-dom'
import Button from '../Button/Button'
import { InlineResponse200, Order, OrderPhase } from '../../types'

interface IOrdersWidget {
    limit?: number
}
const OrdersWidget = ({ limit }: IOrdersWidget) => {
    const [loading, setLoading] = useState(false)
    const [orders, setOrders] = useState<Order[]>([])
    const navigate = useNavigate()

    useEffect(() => {
        let ctrl: AbortController = new AbortController()
        const fetchOrders = async () => {
            setLoading(true)
            try {
                let params: any = {
                    phase: [OrderPhase.Active, OrderPhase.Completed],
                    page: 1,
                    pageSize: limit || 4
                }
                const res: InlineResponse200 = await getOrders(params)
                setOrders(res.items)
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
        }
        fetchOrders()
        return () => ctrl.abort()
    }, [limit])

    return (
        <div className='bg-white border-tibi-fadedPrimary border rounded-md h-auto pt-2'>
            {/* CONTENT */}
            <div className='flex flex-col'>
                {/* LOADER */}
                {loading ? (
                    <div className='w-full flex justify-center pt-8'>
                        <DefaultLoader />
                    </div>
                ) : (
                    orders &&
                    orders.map((order: Order) => {
                        return <OrderItem showPhase key={order.id} data={order} />
                    })
                )}
            </div>
            <div className='px-4 py-4'>
                <Button className='bg-white text-tibi-text border' onClick={() => navigate('orders')}>
                    Alle opdrachten
                    <FontAwesomeIcon icon={faArrowRight} className='ml-2' />
                </Button>
            </div>
        </div>
    )
}

export default OrdersWidget
