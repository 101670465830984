import { fetchAuthSession } from 'aws-amplify/auth'
import {
    ExtendedProduct,
    ExtendedProductOption
} from '../components/ProductOptionsModalContent/ProductOptionsModalContent'

export const getUser = async () => {
    const data = fetchAuthSession().then((data: any) => {
        return data
    })
    return data
}

export const getEmailName = (email: string | undefined) => (email ? email.split('@').shift() : '')

export const getFirstCharacters = (email: string | undefined) => {
    const name = getEmailName(email)
    return name?.slice(0, 2)
}

export const filterUndefinedFormFields = (obj: object) => {
    const temp: any = { ...obj }
    for (let key in temp) {
        if (temp[key] === undefined) delete temp[key]
    }
    return temp
}

export const formatCurrencyValue = (value: number) => {
    return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(value)
}

export const timeSince = (date: any) => {
    const now: any = new Date()
    var seconds = Math.floor((now - date) / 1000)

    var interval = seconds / 31536000

    if (interval > 1) {
        return Math.floor(interval) + ' jaar geleden'
    }
    interval = seconds / 2592000
    if (interval > 1) {
        return Math.floor(interval) + ' maanden geleden'
    }
    interval = seconds / 86400
    if (interval > 1) {
        return Math.floor(interval) + ' dagen geleden'
    }
    interval = seconds / 3600
    if (interval > 1) {
        return Math.floor(interval) + ' uren geleden'
    }
    interval = seconds / 60
    if (interval > 1) {
        return Math.floor(interval) + ' minuten geleden'
    }
    return Math.floor(seconds) + ' seconden geleden'
}

export const returnInitialState = (storageKey: string) => {
    try {
        // Get from local storage by key
        const item = window.localStorage.getItem(storageKey)
        // Parse stored json or if none return an empty object
        return item ? JSON.parse(item) : {}
    } catch (error) {
        // If error also return an empty object
        console.log(error)
        return {}
    }
}

export const formatDate = (
    dateString: string | Date | undefined,
    dateOptions: any = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }
) => {
    if (!dateString) return
    return new Date(dateString).toLocaleDateString('nl-NL', dateOptions)
}

export const formatTime = (dateString: string | Date | undefined) => {
    if (!dateString) return
    return new Date(dateString).toLocaleTimeString('nl-NL', {
        hour: '2-digit',
        minute: '2-digit'
    })
}

export const isToday = (dateString: string | Date | undefined) => {
    if (!dateString) return
    const date = new Date(dateString)
    const today = new Date()
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    )
}

export const isYesterday = (dateString: string | Date | undefined) => {
    if (!dateString) return
    const date = new Date(dateString)
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    return (
        date.getDate() === yesterday.getDate() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getFullYear() === yesterday.getFullYear()
    )
}

export const hyphensToCamelCase = (str: string) => str.replace(/-([a-z])/g, (g) => g[1].toUpperCase())

export const calculateFrontendTotalPrice = (products: ExtendedProduct[]) => {
    const total = products.map((p) => p.price).reduce((partialSum, a) => partialSum + Number(a), 0)
    // @ts-ignore
    const allOptions = products
        .filter((p: ExtendedProduct) => p.availableOptions) // only get basket items with available options
        ?.map((p: ExtendedProduct) => p.availableOptions) // get list of options only
        .flat() as ExtendedProductOption[] // flatten array

    const totalOptionValue = allOptions
        .filter((a: ExtendedProductOption) => a._isAdded) // filter undefined values
        .map((o: ExtendedProductOption) => o.price) // get price only
        .reduce((partialSum: number, a: number) => partialSum + Number(a), 0) // sum all prices

    return formatCurrencyValue(total + totalOptionValue)
}
